import axios from 'axios';

const url = 'https://simon.zayado.net/public/api/companies';

export async function getAll() {
    try {
        return await axios.get(`${url}`);
    } catch (error) {
        console.error(error);
    }
}

export async function getById(id) {
    try {
        return await axios.get(`${url}/${id}`);
    } catch (error) {
        console.error(error);
    }
}

export async function editData(id, data) {
    try {
        return await axios.put(`${url}/${id}`, data);
    } catch (error) {
        console.error(error);
    }
}

export async function deleteData(id) {
    try {
        return await axios.delete(`${url}/${id}`);
    } catch (error) {
        console.error(error);
    }
}

export async function uploadDta(data) {
    try {
        return await axios.post(`${url}`, data);
    } catch (error) {
        console.error(error);
    }
}
