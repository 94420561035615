import React, { useState, useEffect } from 'react';
import { getAll, editData, deleteData, uploadDta } from './apIService';
import { TextField, Button, List, ListItem, ListItemText, Typography, Paper, Grid } from '@mui/material';
import './App.css';


const App = () => {
    const [companies, setCompanies] = useState([]);
    const [form, setForm] = useState({ name: '', email: '', address: '', website: '' });
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        const response = await getAll();
        setCompanies(response.data.data);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (editing) {
            await editData(editing.id, form);
            setEditing(null);
        } else {
            await uploadDta(form);
        }
        setForm({ name: '', email: '', address: '', website: '' });
        fetchCompanies();
    };

    const handleEdit = (company) => {
        setForm(company);
        setEditing(company);
    };

    const handleDelete = async (id) => {
        await deleteData(id);
        fetchCompanies();
    };

    return (
        <div component="main" maxWidth="md" sx={{ paddingTop: 4 }}>
            <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Companies
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Name"
                                name="name"
                                fullWidth
                                variant="outlined"
                                value={form.name}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                name="email"
                                fullWidth
                                variant="outlined"
                                value={form.email}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                name="address"
                                fullWidth
                                variant="outlined"
                                value={form.address}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Website"
                                name="website"
                                fullWidth
                                variant="outlined"
                                value={form.website}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary">
                        {editing ? 'Update' : 'Create'}
                    </Button>
                </form>
                <List>
                    {Array.isArray(companies) && companies.length > 0 ? (
                        companies.sort((a,b)=>((a.id < b.id) ? 1:-1)).map((company) => (
                            <ListItem key={company.id} divider>
                                <ListItemText
                                    primary={`${company.name} - ${company.email}`}
                                    secondary={`${company.address} - ${company.website}`}
                                />
                                <Button onClick={() => handleEdit(company)} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                                    Edit
                                </Button>
                                <Button onClick={() => handleDelete(company.id)} variant="outlined" color="secondary">
                                    Delete
                                </Button>
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText primary="No companies found" />
                        </ListItem>
                    )}
                </List>
            </Paper>
        </div>
    );
};


export default App;
